import React from 'react';
import { graphql } from 'gatsby';
import { Demo, Layout } from 'components';

const DemoPage = ({ data }) => (
  <Layout data={data} locale="en">
    <Demo data={data} locale="en" />
  </Layout>
);

export const query = graphql`
  query {
    datoCmsFooter(locale: { eq: "en" }) {
      instagramLink
      facebookLink
      linkedinLink
      twitterLink
      termsTitle
      terms
      termsOfServiceTitle
      termsOfService
      dataProtectionPolicyTitle
      dataProtectionPolicy
    }
    datoCmsHomepage(locale: { eq: "en" }) {
      paprworkLink
    }
    datoCmsDemo(locale: { eq: "en" }) {
      title
      contactByPhoneIcon {
        url
      }
      contactByPhoneTitle
      contactByPhoneDescription
      phoneNumber
      contactIcon {
        url
      }
      contactTitle
      contactCta
      contentSuccessfulContactFormNode {
        childMarkdownRemark {
          html
        }
      }
      backToContactForm
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }
    datoCmsSite(locale: { eq: "en" }) {
      globalSeo {
        fallbackSeo {
          description
          title
          twitterCard
          image {
            url
          }
        }
        siteName
        twitterAccount
      }
    }
  }
`;

export default DemoPage;
